// @flow

import React from 'react';
import { useAnalytics } from '../../common/analytics';
import { isProd } from '../../common/helpers/isProd';
import type { Node } from 'react';

export type WrapperPropsType = {
  children: any
};

const Wrapper = ({ children }: WrapperPropsType): Node => {
  const non_prod_key = process.env.GATSBY_SEGMENT_KEY_NON_PROD;
  const prod_key = process.env.GATSBY_SEGMENT_KEY_PROD;
  const key = isProd() ? prod_key : non_prod_key;

  useAnalytics(key);
  return <>{children}</>;
};

export default Wrapper;
