import React from 'react';
import Wrapper from 'src/components/Wrapper/Wrapper';

export const onClientEntry = () => {
  window.onload = () => {
    const el = document.createElement('div');
    el.setAttribute('id', 'portal');
    document.body.appendChild(el);
  };
};

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
};
