import { _dx } from '@dx/./analytics';
import Cookies from 'js-cookie';
import { config } from 'src/common/config';
import { useState, useEffect } from 'react';

const segmentDestinations = require('../data/segmentDestinations.json');

const TRUSTARC_MESSAGE_ORIGINS = [
  'https://consent.trustarc.com',
  'https://consent-pref.trustarc.com'
];

export const isFunctionalCookieEnabled = () => {
  const consentCookieValue = Cookies.get('notice_gdpr_prefs');
  return consentCookieValue !== undefined && consentCookieValue.includes('1');
};

const useTrustArcConsentListener = () => {
  const [functionalCookieEnabled, setFunctionalCookieEnabled] = useState(
    isFunctionalCookieEnabled()
  );

  window.addEventListener(
    'message',
    (e) => {
      if (!TRUSTARC_MESSAGE_ORIGINS.includes(e.origin)) {
        return;
      }
      let data = e.data;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.log(e);
        }
      }
      if (data && data.source === 'preference_manager' && data.message === 'remove_iframe') {
        setFunctionalCookieEnabled(isFunctionalCookieEnabled());
      }
    },
    false
  );
  return functionalCookieEnabled;
};

export const disableAnalytics = () => {
  document.querySelectorAll('script[src*="cdn.segment.com"]').forEach((node) => node.remove());
  document.querySelectorAll('script[src*="fast.appcues.com"]').forEach((node) => node.remove());
  document.querySelectorAll('link[href*="fast.appcues.com"]').forEach((node) => node.remove());

  window.FS && window.FS.shutdown();
  window.Appcues = window.analytics = void 0;
};

export const conditionallyLoadAnalytics = (
  segmentKey,
  isTrustArcEnabled,
  isConsentCookieEnabled
) => {
  if (isTrustArcEnabled && !isConsentCookieEnabled) {
    disableAnalytics();
    return;
  }
  if (!window.analytics && segmentDestinations.length) {
    const destinationPreferences = segmentDestinations.reduce((acc, destination) => {
      return {
        ...acc,
        [destination.creationName]: destination.category === 'Analytics'
      }; // enable only analytic destinations for functional cookies
    }, {});

    _dx.loadDxUtility(segmentKey, {
      ...destinationPreferences
    });

    if (window && window.analytics && window.analytics.user) {
      _dx.identify();
    } else {
      const interval = setInterval(() => {
        if (window && window.analytics && window.analytics.user) {
          clearInterval(interval);
          _dx.identify();
        }
      }, 1000);
    }

    window.FS && window.FS.restart();
  }
};

export const useAnalytics = (segmentKey) => {
  const isConsentCookieEnabled = useTrustArcConsentListener();

  useEffect(() => {
    const isTrustArcEnabled = config.cookieBanner;

    if (!isTrustArcEnabled) {
      return;
    }
    conditionallyLoadAnalytics(segmentKey, isTrustArcEnabled, isConsentCookieEnabled);
  }, [segmentKey, isConsentCookieEnabled]);
};
