// @flow

export function isProd(): boolean {
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.hostname === 'ix.equinix.com'
  ) {
    return true;
  }
  return false;
}
